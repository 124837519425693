import { render, staticRenderFns } from "./authorizeTransfer.vue?vue&type=template&id=0e17aeee&scoped=true"
import script from "./authorizeTransfer.vue?vue&type=script&lang=js"
export * from "./authorizeTransfer.vue?vue&type=script&lang=js"
import style0 from "./authorizeTransfer.vue?vue&type=style&index=0&id=0e17aeee&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e17aeee",
  null
  
)

export default component.exports