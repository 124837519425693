<!--
* @Author: tianzl
* @Date: 2023-11-21 17:32:33
* @LastEditors: tianzl
* @LastEditTime: 2023-11-21 17:32:33
* @Description: 授权他人接待
 -->
<template>
<van-dialog :value="modelValue" 
  :title="$t('提示')" 
  show-cancel-button
  overlay-class="author-transfer-dialog" 
  :before-close="handlerConfirm"
  :close-on-click-overlay="true"
  :cancel-button-text="isAuth?$t('修改授权'):$t('取消')" 
  :confirm-button-text="isAuth?$t('取消授权'):$t('确认')" 
  confirmButtonColor="#B9921A"
  :width="400"
  class="author-transfer-dialog">
  <div v-if="isAuth" class="desc">{{$t('该线索已授权')}}{{ clueDetails.authorizedEmployeeName }}{{$t('可接待')}}</div>
  <div v-else>
    <p class="desc">{{$t('授权后，他人可代替接待客户并填写一次跟进记录')}}</p>
    <van-form :show-error="false">
      <van-field v-model="assignUserName"
        name="assignUserId" 
        required
        :label="$t('授权顾问')"
        readonly 
        :placeholder="$t('请选择授权顾问')"
        :rules="[{ required: true }]"/>
    </van-form>
    <Adviser v-model="assignUserId" 
      :dataList="empList" 
      :config="{ nodeKey: 'id', nodeName: 'name' }"
      style="margin-left: 16px;"
      @change="onSelect">
    </Adviser>
  </div>
</van-dialog>
</template>
<script>
import Adviser from '@/modules/clueDefeat/adviser.vue'
import clueServices from '@/services/clueServices.js'
export default {
  components:{ Adviser },
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props:{
    modelValue:{
      type: Boolean,
      default: false
    },
    clueDetails:{
      type: Object,
      default: ()=>({}),
      required: true
    }
  },
  data(){
    return {
      assignUserId: '',
      assignUserName: '',
      empList: [],
      isUpdate: false
    }
  },
  computed:{
    isAuth(){
      return !!this.clueDetails.authorizedEmployeeId
    }
  },
  watch:{
    modelValue:{
      handler(val){
        this.$emit('change',val)
      }
    }
  },
  async mounted(){
    const res = await clueServices.getMasterBuddy()
    this.empList = res
    console.log('getMasterBuddy res:',res)
  },
  methods:{
    // 授权
    async authPost(){
      const params = {
        authorizedEmployeeId: this.assignUserId,
        authorizedEmployeeName: this.assignUserName,
        id:this.clueDetails.id,
      }
      const res = await clueServices.empowerPost(params)
    },
    // 取消授权
    async authCancelPost(){
      const params = { id:this.clueDetails.id }
      const res = await clueServices.cancelEmpowerPost(params)
    },
    // 修改授权
    async authUpdate(){
      const params = {
        authorizedEmployeeId: this.assignUserId,
        authorizedEmployeeName: this.assignUserName,
        id:this.clueDetails.id,
      }
      const res = await clueServices.updateEmpowerPost(params)
    },
    async handlerConfirm(action,done){
      if (action==='overlay') {
        this.$emit('refresh')
      }
      if (this.isAuth&&action==='cancel'){
        // 修改授权
        this.isUpdate = true
        this.assignUserId = this.clueDetails.authorizedEmployeeId
        this.assignUserName = this.clueDetails.authorizedEmployeeName
        this.clueDetails.authorizedEmployeeId = ''
        done(false)
      } else if (this.isAuth&&action==='confirm'){
        // 取消授权
        await this.authCancelPost()
        this.$emit('refresh')
      } else if (!this.isAuth&&action==='cancel'){
        // 取消
        this.$emit('refresh')
      } else if (!this.isAuth&&action==='confirm'){
        if (!this.assignUserId) {
          this.$toast(this.$t('请选择授权顾问'))
          done(false)
          return 
        }
        // 需要判断是授权还是修改授权
        if (this.isUpdate){
          await this.authUpdate()
        } else {
          await this.authPost()
        }
        this.$emit('refresh')
      }

    },
    onSelect(id,item){
      this.assignUserName = item.name
    }
  }
}
</script>
<style lang="less" scoped>
.desc{
  padding-left: 16px;
  font-size: 14px;
  margin: 16px 0;
}
.author-transfer-dialog{
  padding: 0 16px;
}
</style>